import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import ScrollOut from "scroll-out"

import ArticleHeaderGrid from "../../../components/athena/articleHeaderGrid"
import ContributorsGrid from "../../../components/athena/contributorsGrid"
import MoreArticlesGrid from "../../../components/athena/moreArticlesGrid"
import AthenaCta from "../../../components/athena/athenaCta"
import Layout, { athenaTheme } from "../../../components/layout"
import PreFooter from "../../../components/athena/preFooter"
import ScrollProgressBar from "../../../components/scrollProgressBar"
import GridCollagePhoto from "../../../components/athena/gridCollagePhoto"
import { getMaxSectionImageWidth } from "./cycle3-shared"

import styles from "./collective-genius.module.scss"
import sharedStyles from "./cycle3-shared.module.scss"

let theme = {
  ...athenaTheme,
  background: styles.background,
  foreground: styles.foreground,
  headerPosition: styles.position,
}

const Article = ({ data }) => {
  const article = data.craft.article
  const seo = article.seomatic
  const athenaCta = article.athenaCta

  // Images
  const {
    headerImage,
    section1Image,
    section2Image,
    section3Image,
  } = data.craft
  const coverImages = article.coverImages ? article.coverImages[0] : null

  let maxSectionImageWidth = getMaxSectionImageWidth()

  // Initialize up ScrollOut library
  useEffect(() => {
    let so = ScrollOut({
      threshold: 0.1,
      cssProps: {
        viewportY: true,
      },
    })

    return so.teardown
  }, [])

  return (
    <Layout seo={seo} mode="athena" theme={theme} shyHeader={false}>
      <Helmet>
        <body className={`athena ${styles.body}`} />
      </Helmet>
      <article>
        <ScrollProgressBar />
        <div className={styles.article}>
          <div className={styles.articleBody}>
            <div className={styles.text}>
              <div className={`${styles.narrow} wrapper`}>
                <div
                  className={`${styles.headerContentBlock} columns is-tablet is-centered`}
                >
                  <ArticleHeaderGrid
                    titleColor="#F794A5"
                    issue={article.issue}
                    title={article.title}
                    subtitle={article.articleSubTitle}
                    headerImage={headerImage}
                  />
                </div>
                <div
                  className={`${styles.contentBlock} columns is-tablet is-centered`}
                >
                  <div className="column is-10-tablet">
                    <div className="mb-8 user-content-athena">
                      <p>
                        What sort of creativity can flow from our combined
                        experiences and perspectives?
                      </p>
                      <p>
                        Variations of this question are on the mind of many, but
                        the structural and cultural practices needed to realize
                        the answer often remain elusive.
                      </p>
                      <p>
                        In business and beyond, a &ldquo;culture of
                        genius&rdquo; takes hold in many organizations.
                        Breakthrough ideas and innovation are viewed as holy
                        ground reserved for a select individual or group whose
                        thinking guides the collective trajectory. These leaders
                        hold decision-making power, establish company culture,
                        and choose who succeeds. Members of the organization
                        pursue success by shifting behaviors and mindsets to
                        blend with established norms, creating a monoculture
                        with a singular perspective.
                      </p>
                      <p>
                        In environments built around a culture of genius, there
                        is a prevailing notion that each individual has the
                        unique skills to succeed; it&rsquo;s just a matter of
                        the right person recognizing them. This elevation of
                        self sets a standard of competition throughout the
                        organization as individuals seek to outperform each
                        other. An organization&rsquo;s core purpose can be
                        diluted or supplanted entirely by competition. Trust is
                        low, and fear of failure is high. The initial motivation
                        of competition leads to burnout (looking at you, &ldquo;
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.nytimes.com/2019/01/26/business/against-hustle-culture-rise-and-grind-tgim.html"
                        >
                          hustle culture
                        </a>
                        &rdquo;), and overall progress toward goals, both at
                        work and in society, decreases.
                      </p>
                      <p>
                        A culture of genius is often accompanied by a reverence
                        for specialization that limits interdisciplinary work
                        with a &ldquo;stay in your lane&rdquo; mindset: project
                        managers manage, designers design, and so on. This
                        approach is preferred for its perceived efficiency, but
                        it negatively affects the way individuals view their
                        abilities beyond their specific role, in addition to
                        creating imbalanced power dynamics and inhibiting
                        collective potential.
                      </p>

                      <p>
                        Iterations of this culture are the default across most
                        organizations, but several decades of{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://hbr.org/2014/06/collective-genius"
                        >
                          research
                        </a>{" "}
                        indicates that this model persists based on the inertia
                        of tradition more than a demonstrated record of success.
                      </p>
                      <h2 style={{ color: "#F794A5" }}>
                        Here&rsquo;s how we begin to move forward:
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={styles.twoColumn}
              style={{ backgroundColor: "#394746" }}
            >
              <div>
                <div className={sharedStyles.stickyPhotoWrapper}>
                  <GridCollagePhoto
                    src={section1Image.url}
                    width={section1Image.width}
                    height={section1Image.height}
                    maxWidth={maxSectionImageWidth}
                    imgix={true}
                    cols={8}
                    rows={12}
                    altText={section1Image.altText}
                  />
                </div>
              </div>
              <div className={styles.rightColumn}>
                <h3
                  className={styles.contentHeader}
                  style={{ color: "#F0E7CC" }}
                >
                  Embrace Fluidity
                </h3>
                <div className="user-content-athena">
                  <p>
                    Organizations that continually reimagine and reinvent
                    what&rsquo;s possible don&rsquo;t place constraints on
                    innovation. Collective genius is cultivated by empowering
                    individuals to engage, experiment, and create together. With
                    less rigidity and greater autonomy, the{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://zeusjones.com/athena/articles/why-its-time-for-business-to-look-to-the-octopus"
                    >
                      fluid organization
                    </a>{" "}
                    is the ambition and progress of its members. Instead of job
                    postings that describe ideal candidates as &ldquo;creative
                    rock stars&rdquo; or &ldquo;social media gurus&rdquo;, these
                    organizations avoid ego-driven competition and seek out
                    creative curiosity and hybrid skill sets that form the
                    foundation of cooperative environments. Employees are given
                    the time and resources to develop skills. As new
                    capabilities emerge, the collective becomes more adaptable
                    and resilient from within.
                  </p>
                </div>
              </div>
            </div>
            <div
              className={styles.twoColumn}
              style={{ backgroundColor: "#994534" }}
            >
              <div>
                <div className={sharedStyles.stickyPhotoWrapper}>
                  <GridCollagePhoto
                    src={section2Image.url}
                    width={section2Image.width}
                    height={section2Image.height}
                    maxWidth={maxSectionImageWidth}
                    imgix={true}
                    cols={8}
                    rows={12}
                    altText={section2Image.altText}
                  />
                </div>
              </div>
              <div className={styles.rightColumn}>
                <h3
                  className={styles.contentHeader}
                  style={{ color: "#F0B8AD" }}
                >
                  Amplify Imagination
                </h3>
                <div className="user-content-athena">
                  <p>
                    The evolution of technology is continually accelerating the
                    rate of change in the world around us. Increased
                    optimization and automation narrows pathways for organic,
                    uninhibited imagination. While the day may come that
                    artificial intelligence is more creative than the human
                    mind; it isn&rsquo;t here yet. In the meantime, we need to
                    double-down on our greatest strengths: imagination and
                    critical thinking. This begins by moving beyond systems that
                    commodify creativity with assembly-line efficiency and make
                    way for the dreaming, curiosity, and experimentation that
                    will create a more cooperative future.
                  </p>
                </div>
              </div>
            </div>
            <div
              className={styles.twoColumn}
              style={{ backgroundColor: "#414A52" }}
            >
              <div>
                <div className={sharedStyles.stickyPhotoWrapper}>
                  <GridCollagePhoto
                    src={section3Image.url}
                    width={section3Image.width}
                    height={section3Image.height}
                    maxWidth={maxSectionImageWidth}
                    imgix={true}
                    cols={8}
                    rows={12}
                    altText={section3Image.altText}
                  />
                </div>
              </div>
              <div className={styles.rightColumn}>
                <h3
                  className={styles.contentHeader}
                  style={{ color: "#D0EAF2" }}
                >
                  Empower Variation
                </h3>
                <div className="user-content-athena">
                  <p>
                    Let&rsquo;s borrow a lesson from a few billion years ago to
                    better understand the tradeoffs between variation and
                    optimization. The earliest protozoans on earth reproduced
                    through asexual cloning. Cell mimicry was incredibly
                    efficient, but as it turned out, organisms with identical
                    DNA were susceptible to dying off from the same factors. To
                    survive, organisms began to reproduce through recombination-
                    a process we&rsquo;re more familiar with today because
                    it&rsquo;s worked pretty well for the past 3 billion years
                    or so. The genetic variation that came from a 50/50 DNA
                    split allowed organisms to adapt to survive disease,
                    predators, climate fluctuations and the like. The same
                    evolutionary logic holds true when it comes to innovation.
                    Ideas from a single source typically lack the variation to
                    overcome challenges. Exchanging ideas from a multitude of
                    perspectives allows us to combine unique elements to create
                    stronger ideas. This process of recombination takes more
                    time, but when ideas come to life, they&rsquo;re more likely
                    to be a true evolution.
                  </p>
                  <p>
                    When we reject the rigidity of a culture of genius and usher
                    in creativity from a diversity of perspectives, we can
                    unleash the free-flowing innovation needed to take on bigger
                    challenges with bolder solutions.
                  </p>
                </div>
              </div>
            </div>
            <ContributorsGrid
              zeus={article.zeusContributors}
              other={article.otherContributorsGrid}
            />
            <MoreArticlesGrid id={article.id} issue={article.issue[0].id} />
            <AthenaCta data={athenaCta} />
          </div>
          {/* .article-body */}
        </div>
        {/* .article */}
      </article>
      <PreFooter />
    </Layout>
  )
}

export default Article

export const query = graphql`
  query($slug: String!) {
    craft {
      article: entry(slug: [$slug], section: "athenaArticles") {
        id
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_athenaArticles_athenaArticleCustom_Entry {
          articleSubTitle
          issue {
            id
            title
          }
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_16x9 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
              ratio_4x3 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
            }
          }
          zeusContributors {
            id
            title
            uri
            ... on Craft_people_person_Entry {
              portrait {
                url
                width
                height
                focalPoint
                ... on Craft_people_Asset {
                  altText
                }
              }
            }
          }
          otherContributors {
            ... on Craft_otherContributors_TableRow {
              name
              website
            }
          }
          otherContributorsGrid {
            ... on Craft_otherContributorsGrid_BlockType {
              contributorName
              contributorWebsite
              contributorPhoto {
                url
                width
                height
                focalPoint
                ... on Craft_athenaContent_Asset {
                  altText
                }
              }
            }
          }
          athenaCta {
            ...AthenaCta
          }
        }
      }
      headerImage: asset(id: 24073) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      section1Image: asset(id: 24074) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      section2Image: asset(id: 24075) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      section3Image: asset(id: 24076) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
    }
  }
`
